<template>
  <v-row>
    <v-col>
      <v-btn
        v-if="$store.state.userlogeado.rol == 1"
        small
        @click="dialog = true"
        color="success"
        class="mx-3 my-3"
        ><v-icon left>mdi-plus</v-icon> Nuevo Voluntario</v-btn
      >

      <v-sheet elevation="10" class="">
        <v-text-field
          class="ml-5 mt-2"
          name="buscar"
          label="Buscar"
          v-model="buscar"
        ></v-text-field>
      </v-sheet>
      <v-data-table
        dense
        :headers="cabecera"
        :items="voluntarios"
        :search="buscar"
        class="elevation-10"
        :sort-desc="false"
      >
      <template v-slot:[`item.name`]="{ item }">
          <span>{{ item.name }} {{ item.fathersSurname }} {{ item.mothersSurname }}</span>
        </template>
        <template v-slot:[`item.acc`]>
          <a><v-icon>mdi-file-document-edit-outline</v-icon></a>
        </template>
      </v-data-table>
    </v-col>
    <v-dialog v-model="dialog" persistent :overlay="false" max-width="700px">
      <v-card>
        <v-card-title> Crear Voluntario ---> {{ nombreCompuesto }}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col md="4">
              <v-text-field
                name="Nombres"
                label="Nombres"
                v-model="nuevoVoluntario.name"
                id="nom1"
              ></v-text-field>
            </v-col>
            <v-col md="4">
              <v-text-field
                name="apellido-paterno"
                label="Apellido Paterno"
                v-model="nuevoVoluntario.fathersSurname"
                id="ap1"
              ></v-text-field>
            </v-col>
            <v-col md="4">
              <v-text-field
                name="apellido-materno"
                label="Apellido Materno"
                v-model="nuevoVoluntario.mothersSurname"
                id="ap2"
              ></v-text-field>
            </v-col>
            <v-col md="6">
              <v-text-field
                name="rut"
                type="string"
                v-mask="'########-N'"
                mask="########-N"
                label="Rut"
                v-model="nuevoVoluntario.rut"
                id="rut"
              ></v-text-field>
            </v-col>
            <v-col md="6">
              <v-text-field
                name="rut"
                type="number"              
                label="Registro Cuerpo"
                v-model.number="nuevoVoluntario.registryNumber"
                id="rcuerpo"
              ></v-text-field>
            </v-col>
            <v-col md="6">
              <v-text-field
                name="fecha-nacimiento"
                type="date"
                label="Fecha Nacimento"
                v-model="nuevoVoluntario.birthdate"
                id="nacimento"
              ></v-text-field>
            </v-col>
            <v-col md="6">
              <v-text-field
                name="direccion"
                type="string"
                label="direccion"
                v-model="nuevoVoluntario.address"
                id="direccion"
              ></v-text-field>
            </v-col>
            <v-col md="6">
              <v-text-field
                name="ciudad"
                type="string"
                label="Ciudad "
                v-model="nuevoVoluntario.city"
                id="ciudad"
              ></v-text-field>
            </v-col>
            <v-col md="6">
              <v-text-field
                name="telefono"
                type="string"
                label="Telefono "
                v-model="nuevoVoluntario.mobilePhone"
                id="fono"
              ></v-text-field>
            </v-col>
            <v-col md="6">
              <v-text-field
                name="Email"
                type="mail"
                label="E-Mail "
                v-model="nuevoVoluntario.email"
                id="mail"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="ceraraDialog" outlined dark color="warning"
            >cancelar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn @click="guardarNuevoEmpleado" color="primary"
            >GUARDAR CAMBIO</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";
export default {
  name: "empleado-admin",
  data() {
    return {
      dialog: false,
      buscar: "",
      cabecera: [
        { text: "Nombre", value: "name" },
        { text: "Rut", value: "rut" },
        { text: "Cargo", value: "cargo" },
        { text: "Acciones", value: "acc" },
      ],
      voluntarios: [],
      usuarioEnEdicion: "",
      nuevoVoluntario: 
        {
          rut: "",
          name: "",
          fathersSurname: "",
          mothersSurname: "",
          registryNumber: "",
          address: "",
          city:"",
          mobilePhone:"",
          email:"",
          birthdate:"",
        },
      
    };
  },

  methods: {
    ceraraDialog: function () {
      this.dialog = false;
    },
    abrirDialog: function (usuario) {
      console.log(usuario);
      this.dialog = true;
    },
    guardarNuevoEmpleado: function () {
      console.log("la data:", this.nuevoVoluntario);
      axios
        .post(`${process.env.VUE_APP_BASE_URL}/api/firefighter/new`, this.nuevoVoluntario)
        .then((response) => {
          //this.items_bodega = response.data[0].assigned_items
          console.log(response);
        });
          this.nuevoVoluntario.rut=  ""
          this.nuevoVoluntario.name=  ""
          this.nuevoVoluntario.fathersSurname=  ""
          this.nuevoVoluntario.mothersSurname=  ""
          this.nuevoVoluntario.registryNumber=  ""
          this.nuevoVoluntario.address=  ""
          this.nuevoVoluntario.city= ""
          this.nuevoVoluntario.mobilePhone= ""
          this.nuevoVoluntario.email= ""
          this.nuevoVoluntario.birthdate= ""
          this.ceraraDialog()
          axios.get(`${process.env.VUE_APP_BASE_URL}/api/firefighter/getAll`).then((response) => {
          this.voluntarios = response.data;
    });    
    },
  },
  computed: {
    nombreCompuesto: function () {
      return (
        `${this.nuevoVoluntario.name} ` +
        `${this.nuevoVoluntario.fathersSurname} ` +
        `${this.nuevoVoluntario.mothersSurname} ` 
      );
    },
  },
  mounted(){
    axios.get(`${process.env.VUE_APP_BASE_URL}/api/firefighter/getAll`).then((response) => {
      this.voluntarios = response.data;
    });
  }

  
};
</script>
