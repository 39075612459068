<template>
  <v-row>
    <v-col md="12" cols="12">
      <v-btn
        small
        @click="dialogCrearHerramienta = true"
        color="success"
        class="mx-3 my-3"
        ><v-icon left>mdi-plus</v-icon> Crear equipo/herramienta</v-btn
      >
      <v-sheet elevation="10" class="mb-3 py-4">
        <v-text-field
          
          class="mx-4 "
          hide-details
          label="Buscar"
          v-model="buscar"
        ></v-text-field>
      </v-sheet>
      <v-data-table
        :headers="cabecera"
        :items="items"
        :search="buscar"
        class="elevation-5"
      ></v-data-table>
    </v-col>

    <v-dialog
      v-model="dialogCrearHerramienta"
      persistent
      :overlay="false"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title>Crear Equipo/herramienta</v-card-title>
        <v-card-text>
          <v-form ref="formCrearHerramienta" v-model="crearHerramienta">
            <v-row>
              <v-col>
                <v-select
                  dense
                  outlined
                  :items="$store.state.tipos"
                  item-text="name"
                  item-value="id"
                  label="Tipo implemento"
                  v-model="nuevoItem.fk_type"
                  :rules="requerido"
                  required
                ></v-select>
                <v-select
                  dense
                  outlined
                  :items="$store.state.marcas"
                  item-text="name"
                  item-value="id"
                  label="Marca"
                  v-model="nuevoItem.fk_make"
                  :rules="requerido"
                  required
                ></v-select>
                <v-select
                  dense
                  outlined
                  :items="$store.state.modelos"
                  item-text="name"
                  item-value="id"
                  label="Modelo"
                  v-model="nuevoItem.fk_model"
                  :rules="requerido"
                  required
                ></v-select>

                <v-text-field
                  dense
                  outlined
                  label="Numero de Serie"
                  v-model="nuevoItem.serial_number"

                ></v-text-field>
               
              </v-col>
              <v-col>
                <v-select
                  dense
                  outlined
                  :items="$store.state.estados"
                  item-text="condition"
                  item-value="id"
                  label="Estado"
                  v-model="nuevoItem.fk_condition"
                  :rules="requerido"
                  required
                ></v-select>
                <v-text-field
                  dense
                  outlined
                  label="Talla"
                  v-model="nuevoItem.size"
                ></v-text-field>
                <v-text-field
                  dense
                  outlined
                  label="Color"
                  v-model="nuevoItem.color"
                  
                ></v-text-field>
                <v-text-field
                  dense
                  outlined
                  label="Numero Interno"
                  v-model="nuevoItem.internal_identifier"

                ></v-text-field>
                
                
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  dense
                  outlined
                  label="Observaciones"
                  v-model.number="nuevoItem.observations"
                 
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="warning"
            @click="
              dialogCrearHerramienta = false;
              $refs.formCrearHerramienta.reset();
            "
            outlined
            >cancelar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!crearHerramienta"
            color="primary"
            @click="crearNuevoItem"
            >GUARDAR</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import axios from "axios";

export default {
  name: "herramientas-panel",
  data() {
    return {
      dialogCrearHerramienta: false,
      crearHerramienta: false,
      buscar:"",
      cabecera: [
        { text: "Tipo", value: "type.name" },
        { text: "Marca", value: "make.name" },
        { text: "Modelo", value: "model.name" },
        { text: "N-Serie", value: "serial_number" },
        { text: "N-Interno", value: "num_interno" },
        { text: "en bodega", value: "assigned_to.name" },
        { text: "Acciones", value: "acciones" },
      ],
      items: [],
      nuevoItem: {
        fk_type: "",
        fk_make: "",
        fk_model: "",
        size:'',
        color:'',
        fk_condition:'',
        serial_number: '',
        internal_identifier: '',
        observations:'',
        fk_ownership: 1,
        fk_firefighter: 3
      },
      requerido: [(v) => !!v || "Dato Obligatorio"],
    };
  },

  methods: {
    crearNuevoItem:function(){
      axios.post(`${process.env.VUE_APP_BASE_URL}/api/item/new`, this.nuevoItem).then((response) => {
        this.dialogCrearHerramienta = false;
        this.$swal("Se a creado un nuevo item, ya esta disponible para su asignacion FZJP");
    });
    },
  },
  mounted(){
    axios.get(`${process.env.VUE_APP_BASE_URL}/api/item/getAll`).then((response) => {
      this.items = response.data;
    });
  }
};
</script>
